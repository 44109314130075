import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from "../assets/images/logo.svg"
import { Link } from 'react-router-dom';
import { AuthContext } from '../context';
import { io } from 'socket.io-client';
import { BASE_URL } from '../constanta/apiConfig';
import { useNavigate } from 'react-router-dom';


function Header() {
    const {auth, setAuth} = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null)
    const dropwnRef = useRef(null)
    const [summ, setSumm] = useState('')
    const navigate = useNavigate();
    useEffect(() => {
        let changeSumm = String(auth.balance).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        setSumm(changeSumm);
    }, [auth.balance]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const socketInfoUser = io(`${BASE_URL}`);
        socketInfoUser.on(auth.login+':info_user', (data) => {
            console.log(data)
            if(data.ban){
                setAuth({...auth, loading: true})
            }
            else{
                setAuth({...auth, loading: false, verification: data.verification, balance: data.balance, isFaqFounds: data.isFaqFounds})
            }
            
        })

        const handleClickOutside = (event) => {
          if (!wrapperRef.current.contains(event.target) && !dropwnRef.current.contains(event.target)) {
            setIsOpen(false)
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };

        

      }, []);

      

      

    return (
        <header class="header">
            
        <div class="container header__container">
            <Link class="header__logo" to="/">
                <img class="header__logo" src={logo} alt="logo"/>
            </Link>
            <div class="user_balance_nav">
                <div class="balance_nav">
                    <div class="summ_sign_balance">
                        <span class="sign_balance" style={{marginRight: 3 + 'px'}}>₽</span>
                        <span class="summ_balance">{summ}</span>
                    </div>
                </div>
                <div class="user_nav" onClick={toggleDropdown} ref={dropwnRef}>
                    <div class="avatar_user">
    
                    </div>
                    <span className={"arrow_b "+(isOpen ? 'arrow_active' : '')}></span>
                </div>
                
            </div>
            <div class="info_user" style={{display: isOpen ? 'block' : 'none'}} ref={wrapperRef}>
                    <div class="avatar_login_user">
                        <div class="avatar_user_info">
            
                        </div>
                        <div class="login_id_info_user">
                            <div class="login_info_user">
                                <span class="label">Логин</span>
                                <span class="login_info_user_data summ_balance">{auth.login}</span>
                            </div>
                            <div class="login_info_user">
                                <span class="label">ID</span>
                                <span class="login_info_user_data summ_balance">{auth.id}</span>
                            </div>
                        </div>
                    </div>
                    <div class="login_id_info_user">
                        <div class="login_info_user">
                            <span class="label">Баланс</span>
                            <div class="login_info_user_data w-100">
                                <span class="sign_balance" style={{marginRight: 3 + 'px'}}>₽</span>
                                <span class="summ_balance">{summ}</span>
                            </div>
                        </div>
                        <div class="login_info_user">
                            <span class="label">Дата регистрации</span>
                            <div class="login_info_user_data w-100">
                                <span class="summ_balance">{auth.data}</span>
                            </div>
                        </div>
                        <div class="login_info_user">
                            <span class="label">Статус верификации</span>
                            <div class="login_info_user_data w-100 blur_login_info">
                                <span class="summ_balance">{auth.verification ? 'Верифицирован' : 'Не верифицирован'}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </header>
    )
}

export default Header;