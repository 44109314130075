import InfoDealItem from "./InfoDealItem";
import cross from "../../assets/images/cross.svg"
import { useState } from "react";
import React from 'react';

function RightSidebar(props){
    const [btnDeal, setBtnDeal] = useState({open_btn: true, close_btn: false})

    return (
        <section className={"right-sidebar "+(props.mobDeals ? 'active' : '')}>
        <div class="right-sidebar__title">
            <h2>Сделки</h2>
            <img class="modal__cross" src={cross} alt="cross" onClick={props.openMobDeal}/>
        </div>
        <div class="right-sidebar__btns">
            <a className={"btn btn_background_gray right-sidebar__btn "+(btnDeal.open_btn ? 'right-sidebar__btn right-sidebar__btn_active' : '')}
            onClick={()=>setBtnDeal({open_btn: true, close_btn: false})}
            >Открытые</a>
            <a className={"btn btn_background_gray right-sidebar__btn "+(btnDeal.close_btn ? 'right-sidebar__btn right-sidebar__btn_active' : '')}
            onClick={()=>setBtnDeal({open_btn: false, close_btn: true})}
            >Закрытые</a>
        </div>
        {
            btnDeal.open_btn ?
                <div class="right-sidebar__deals">
                    {
                        props.open_deals.length == 0
                        ?
                        <div class="right-sidebar__subtitle right-sidebar__subtitle_center">Нет открытых сделок</div>
                        :
                        <div class="right-sidebar__subtitle right-sidebar__subtitle_center">Ваши открытые сделки</div>

                    }
                    <div class="info_deals">
                        {
                            props.open_deals.slice().reverse().map((deal, index) => {
                                return(
                                    <InfoDealItem key={deal.id} name={deal.name} procent={deal.procent}
                                    time={deal.time} summ={deal.summ} profit={deal.profit} summ_profit={deal.summ_profit}
                                    />
                                )
                            })
                        }
                        
                    </div>
                </div>
            :
                <div class="right-sidebar__deals">
                    {
                        props.close_deals.length == 0
                        ?
                        <div class="right-sidebar__subtitle right-sidebar__subtitle_center">Нет закрытых сделок</div>
                        :
                        <div class="right-sidebar__subtitle right-sidebar__subtitle_center">Ваши закрытые сделки</div>
                    }
                    <div class="info_deals">
                        {
                            props.close_deals.slice().reverse().map((deal, index) => {
                                return(
                                <InfoDealItem key={index} name={deal.name} procent={deal.procent}
                                time={deal.time} summ={deal.summ} profit={deal.profit} summ_profit={deal.summ_profit}
                                />
                                )
                            })
                        }
                    </div>
                </div>
        }
    </section>
    )
}

export default RightSidebar;