import trade from "../../assets/images/trade.svg"
import finance from "../../assets/images/finance.svg"
import help from "../../assets/images/help.svg"
import React from 'react';

function Leftsidebar(props){
    return (
        <section class="left-sidebar">
                  <div class="left-sidebar__blocks">
                      <div class="left-sidebar__block left-sidebar__block_active">
                          <img class="left-sidebar__block-img" src={trade} alt="trade"/>
                          <p class="left-sidebar__block-text">
                              Торговля
                          </p>
                      </div>
                      <div class="left-sidebar__block open-modal" onClick={props.open_finance}>
                          <img class="left-sidebar__block-img" src={finance} alt="help"/>
                          <p class="left-sidebar__block-text">
                              Финансы
                          </p>
                      </div>
                      <div class="left-sidebar__block open-modal" onClick={props.open_help}>
                          <img class="left-sidebar__block-img" src={help} alt="help"/>
                          <p class="left-sidebar__block-text">
                              Помощь
                          </p>
                      </div>
                  </div>
              </section>
    )
}

export default Leftsidebar;