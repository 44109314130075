import up from "../../assets/images/up.svg"
import down from "../../assets/images/down.svg"
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../../constanta/apiConfig";
import Keyboard from "../modals/Keyboard";
import { showError, showInfo, showOpenDeal } from "../../constanta/Toastr";
import React from 'react';

function Deal({unixTimeToHMS, activeCoin, auth, setSocket, setAuth, socketActive}){
    const [isOpen, setIsOpen] = useState(false);
    const [valueDeal, setValueDeal] = useState("00:00:30");
    const [summ, setSumm] = useState({summa: null, profit: 0, summ_profit: 0})
    const [openKeyboard, setOpenKeyboard] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const changeValueDeal = (data) =>{
        setIsOpen(false)
        setValueDeal(data)
    }

    const createDeal = (up_or_down) => {
        if(summ.summa == null){
            showError('Введите сумму')
        }
        
        else if(auth.balance < parseFloat(summ.summa.replace(' ', ''))){
            showError('Недостаточно средств на балансе')

        }else if(parseFloat(summ.summa.replace(' ', '')) < 1000){
            showError('Минимальная сумма для открытия сделки - 1 000₽')
        }
        else{
            axios.post(`${BASE_URL}/api/create-deal`,{
                summ: summ.summa.replace(' ', ''),
                up: up_or_down,
                time: valueDeal,
                coin_name: activeCoin.name
            }, { withCredentials: true })
            .then(res => {
                if(res.data.status == 'ok'){
                    let time = unixTimeToHMS(res.data.close_time)
                    if(res.data.up){
                        showOpenDeal(true, res.data.summa, activeCoin.name)
                    }else{
                        showOpenDeal(false, res.data.summa, activeCoin.name)
                    }
                    let new_balance = Math.round(auth.balance-res.data.summa)
                    setAuth({...auth, balance: new_balance})
                    setSocket(false)
                }else if(res.data.status == 'busy'){
                    showInfo(`Вы не можете открыть сделку на ${res.data.active_coin}, дождитесь завершения предыдущей сделки`)
                
                }else if(res.data.status == 'error betting'){
                    showInfo("В данный момент вы не можете открыть сделку, пожалуйста обратитесь в техническую поддержку для уточнения информации.")
                }else{
                    showError(`Ошибка`)
                }
                
            })
        }
    }

    const chnageSummData  = (inputSumma) => {
        const input = inputSumma.replace(' ', '')
        if (/[a-zA-Zа-яА-Я]|[^\w\s]/.test(input) || input.trim() === '') {
            setSumm({ summa: '', summ_profit: null, profit: null });
        }else if(input.length > 6){
            return
        } 
        else {
            let summa = parseInt(input);
            let profit = Math.round(summa * 0.92)
            let summ_profit = profit + summa;
            let changeSumm = summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            
            setSumm({ summa: changeSumm, summ_profit:summ_profit, profit:profit });
        }
    }

    const chekMobKeyboard=()=>{
        if(window.innerWidth <= 540){
            setOpenKeyboard(true)
        }
    }


    useEffect(()=>{
        if(window.innerWidth <= 540){
            setIsMobile(true)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); 
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
 

    return (
        <div class="deal">
        <div class="deal__start">
            <label class="label">Время</label>
            <div class="select-deal-time-wrapper" ref={dropdownRef}>
                <input class="input input-select deal-time" type="text" value={valueDeal} onClick={toggleDropdown} readOnly/>
                <div className={"select-deal-time "+(isOpen ? 'select-deal-time_active' : '')}>
                    <label class="label">Длительность времени</label>
                    <div class="select-deal-time__btns">
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:00:30")}
                            >30 сек</a>
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:01:00")}
                            >60 сек</a>
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:01:30")}
                            >90 сек</a>
                        <a class="select-deal-time__btn" onClick={() => changeValueDeal("00:02:00")}
                            >120 сек</a>
                    </div>
                </div>
            </div>
            <label class="label modal__label">Сумма</label>
            <div class="modal__input-wrapper modal__input-wrapper-sum" onClick={()=>{chekMobKeyboard()}}>
                <input class="input modal__input modal__input-sum deal-amount" type="text"
                    value={summ.summa} onChange={event => chnageSummData(event.target.value)} autoComplete={isMobile ? "on" : "off"} readOnly={isMobile ? true : false}/>
            </div>
        </div>
        <div class="deal__middle">
            <div class="deal__block">
                <div>Выплата</div>
                <div class="deal__block-wrapper">
                    <div>+92<span>%</span></div>
                    <div><span>₽</span>
                        <p class="bet-amount" style={{display: 'inline'}}>{summ.summ_profit==null ? 0 : summ.summ_profit}</p>
                    </div>
                </div>
                <div class="deal__block-line"></div>
                <div>Прибыль</div>
                <div class="deal__block-wrapper">
                    <div>+<span>₽</span>
                        <p class="bet-profit" style={{display: 'inline'}}>{summ.profit== null ? 0 : summ.profit}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="deal__end">
            <a className={`btn btn_background_green deal__btn ${!socketActive ? 'active_deal' : ''}`} onClick={()=>createDeal(true)}>
                Выше
                <img src={up} alt="up"/>
            </a>
            <a className={`btn btn_background_red deal__btn ${!socketActive ? 'active_deal' : ''}`}onClick={()=>createDeal(false)}>
                Ниже
                <img src={down} alt="down"/>
            </a>
        </div>
        {
        openKeyboard ? <Keyboard close={()=>{setOpenKeyboard(false)}} summ={summ.summa} setSumm={(data)=>{chnageSummData(data)}}/>
        : ''
        }
    </div>
    )
}

export default Deal;