import { useEffect, useContext } from "react";
import { AuthContext } from "../context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constanta/apiConfig";
import { showError } from "../constanta/Toastr";
import React from 'react';

function Logout(){
    const {auth, setAuth} = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(()=>{
        axios.post(`${BASE_URL}/api/logout-user`,{}, { withCredentials: true })
        .then(res => {
            if(res.status == "ok"){
                setAuth({...auth, isAuth: true})
                showError("Вы вышли с аккаунта")
                navigate("/login")
            }else{
                showError("Ошибка")
                navigate("/")
            }
        })  
    }, [])

    return (
        <h1>Logout</h1>
    )
}

export default Logout;