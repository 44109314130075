import Auth from "../components/Auth";
import logo from "../assets/images/logo.svg"
import sign_in from "../assets/images/sign-in.svg"
import sign_up from "../assets/images/sign-up.svg"
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { AuthContext } from "../context";
import { BASE_URL } from "../constanta/apiConfig";
import { showError } from "../constanta/Toastr";
import React from 'react';

function Login(){
    const {auth, setAuth} = useContext(AuthContext)
    const [value, setValue] = useState({login: '', password: ''})
    const navigate = useNavigate();


    const changeLogin=(loginValue)=>{
        setValue({...value, login: loginValue})
    }

    const changePassword=(passwordValue)=>{
        setValue({...value, password: passwordValue})
    }

    useEffect(() => {
        setAuth({...auth, loading: true});
        const timer = setTimeout(() => setAuth({...auth, loading: false}), 500);
        return () => clearTimeout(timer);
      }, []);

    const getAuth=()=>{
        setAuth({...auth, loading: true});
        axios.post(`${BASE_URL}/api/chek-user`, {login: value.login, password: value.password}, { withCredentials: true })
        .then(res => {
          const data = res.data;
          if(!data.auth){
            setAuth({...auth, loading: false});
            showError("Неверный логин или пароль")
            
          }else if(!data.confirm_user){
            showError("Вы не подтвердили аккаунт через телеграм бота")
            setAuth({...auth, loading: false});
          }else{
            setAuth({...auth, isAuth: true})
            navigate("/")
          }
        })
    }

    return (
        <Auth>
            <div class="index_login">
                    <div class="modal__logo">
                        <img src={logo} alt="logo"/>
                    </div>
                    <div class="modal__title">
                        <span class="modal__title-line"></span>
                        <span class="modal__title-text">Вход</span>
                        <span class="modal__title-line"></span>
                    </div>
                    <form class="modal__form" id="sign-in-form">
                        <label class="label modal__label">Логин</label>
                        <input class="input modal__input" type="text" name="login" value={value.login} onChange={event=>changeLogin(event.target.value)} placeholder="Введите логин"/>
                        <label class="label modal__label">Пароль</label>
                        <input class="input modal__input" type="password" name="password" value={value.password} onChange={event=>changePassword(event.target.value)} placeholder="Введите пароль"/>
                    </form>
                    <Link class="modal__link" to="/reset-password">Забыли пароль?</Link>
                    <div class="modal__btns">
                        <a class="btn btn_background_blue modal__btn" id="sign-in-btn" onClick={getAuth}>
                            <img src={sign_in} alt="icon"/>
                            Войти
                        </a>
                        <Link class="btn btn_background_white modal__btn open-modal" modal-name="sign-up" to="/sign-up">
                            <img src={sign_up} alt="icon"/>
                            Создать аккаунт
                        </Link>
                    </div>
            </div>
        </Auth>
    )
}

export default Login;