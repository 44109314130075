import React from 'react';
import cross from "../../assets/images/cross.svg"
import { useState, useEffect } from "react"
import axios from "axios";
import { BASE_URL } from "../../constanta/apiConfig";
import { showInfo } from "../../constanta/Toastr";

function SelectBank({close, amount, setAuth, auth, toStepSecondDeposit, setReqDeposit, setSummDepositSec}){
    const [bankes, setBankes] = useState([])
    const [method, setMethod] = useState('')
    useEffect(() => {
        setAuth({...auth, loading: true})
        axios.post(`${BASE_URL}/api/get-banks`, {}, { withCredentials: true })
        .then(res => {
            setAuth({...auth, loading: false})
            setBankes(res.data.banks)
            setMethod(res.data.type)
        })
        .catch(error => {
            setAuth({...auth, loading: false})
            console.error("Ошибка при получении данных банков:", error);
        });
    }, []);

    const Pay = (bank) =>{
        let select_bank = bankes[bank]
        let summ_ = amount.replace(' ', '')
        setAuth({...auth, loading: true})
                axios.post(`${BASE_URL}/api/create-deposit`,{
                    summa: summ_,
                    type: 'card',
                    name: select_bank.i,
                }, { withCredentials: true })
                .then(res => {
                    setAuth({...auth, loading: false})
                    if(res.data.status == "ok"){
                        toStepSecondDeposit()
                        setReqDeposit(res.data.req)
                        setSummDepositSec(res.data.summ)
                    }else if (res.data.status == "error"){
                        showInfo(`Не удалось создать заявку на пополнение: попробуйте повторить попытку позже или обратитесь в техническую поддержку.`)
                    }
                })
    }

    return (<div class="modal modal__deposit">
        <div class="modal__body modal__body_w_720">
            <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
            <div class="modal__title">
                <span class="modal__title-text">Банковский перевод</span>
                <span class="modal__title-line"></span>
            </div>
                <div class="modal__blocks">
                    {
                        bankes.map((bank, index) => {
                            return(
                                <div key={index} onClick={() => Pay(index)}
                                className={"modal__block modal__deposit-block"}>
                                    {
                                        bank.img == 'visa.svg' ? (
                                            <div style={{display: 'flex', gap: 5+'px'}}>
                                                <img className={"modal__block-img "}
                                                src={require('../../assets/images/mastercard.svg')} alt={bank.name}/> 
                                                <img className={"modal__block-img "}
                                                src={require('../../assets/images/visa.svg')} alt={bank.name}/> 
                                                {
                                                    method == 'merchant' ?
                                                    (<img className={"modal__block-img " }
                                                    src={require('../../assets/images/mir.svg')} alt='MIR'/> )
                                                    : ''
                                                }
                                            </div>
                                        )
                                        : (
                                            <img className={"modal__block-img "+(bank.img == 'sbp.svg' || bank.img == 'mir.svg'  ? "modal__block-img_w_48" : "")}
                                            src={require('../../assets/images/'+bank.img)} alt={bank.name}/> 
                                        )
                                    }
                                                                   
                                    <p class="modal__block-text">
                                        {bank.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
    </div>)
}


export default SelectBank;