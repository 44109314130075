import cross from "../../assets/images/cross.svg"
import deposit from "../../assets/images/deposit.svg"
import withdrawal from "../../assets/images/withdrawal.svg"
import history from "../../assets/images/history.svg"
import { Link } from "react-router-dom"
import React from 'react';

function Finance(props){
    return (
        <div class="modal modal__finance">
                  <div class="modal__body modal__body_w_720">
                      <img class="modal__cross" src={cross} alt="cross" onClick={props.close}/>
                      <div class="modal__title">
                          <span class="modal__title-text">Финансы</span>
                          <span class="modal__title-line"></span>
                      </div>
                      <div class="modal__blocks">
                          <div class="modal__block open-modal" onClick={props.open_deposit}>
                              <img class="modal__block-img" src={deposit} alt="deposit"/>
                              <p class="modal__block-text">
                                  Пополнение баланса
                              </p>
                          </div>
                          <div class="modal__block" onClick={props.open_withdrawal}>
                              <img class="modal__block-img" src={withdrawal} alt="withdrawal"/>
                              <p class="modal__block-text">
                                  Вывод средств
                              </p>
                          </div>
                          <Link class="modal__block" to="/history">
                              <img class="modal__block-img" src={history} alt="history"/>
                              <p class="modal__block-text">
                                  История
                              </p>
                          </Link>
                      </div>
                  </div>
              </div>
    )
}

export default Finance;