import visa from "../assets/images/VISA-logo 1.svg";
import mir from "../assets/images/Group 8830.svg";
import mastercard from "../assets/images/Group.svg";
import qiwi from "../assets/images/qiwi.svg"
import bitcoin from "../assets/images/bitcoin.svg"
import React from 'react';

function HistoryItems({history}){
    return (
        <tr>
                <td>{history.id}</td>
                <td>{history.type}</td>
                <td>{history.data}</td>
                <td>
                    {/* {
                        history.way=='Bitcoin' &&
                        <img src={bitcoin}/>

                    }
                    {
                        history.way=='Qiwi' &&
                        <img src={qiwi}/>
                    }
                    {
                        history.way=='Bank Card (RUB)' &&
                        <div class="history_way">
                            <img src={visa}/>
                            <img src={mir}/>
                            <img src={mastercard}/>
                        </div>
                    } */}
                    
                    <span>{history.way}</span>
                </td>
                <td>
                <span className={"sign_balance "+(history.type == 'Вывод' ? 'c-red' : history.status == 'Успешно' ? 'c-green' : 'c-orange')}>{history.type == 'Вывод' ? '-' : history.status == 'Успешно' ? '+' : ''}₽</span>
                    <span>{String(history.summ).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span>
                </td>
                <td><span className={history.status == 'Успешно' ? 'c-green' : 'c-orange'}>{history.status}</span></td>
        </tr>
    )
}

export default HistoryItems;