import cross from "../../assets/images/cross.svg"
import deposit from "../../assets/images/deposit.svg"
import { useEffect, useState } from "react"
import axios from "axios";
import { BASE_URL } from "../../constanta/apiConfig";
import Keyboard from "./Keyboard";
import { showError, showInfo, showWaitPay } from "../../constanta/Toastr";
import React from 'react';
import sberbank from '../../assets/images/sberbank-3.svg'
import sbp from '../../assets/images/sbp.svg'
import SelectBank from "./SelectBank";

function Deposit({toStepSecondDeposit, pays, setPays, close, summ, setSumm, setReqDeposit, setSummDepositSec, setAuth, auth, showSelectBank}){
    const [openKeyboard, setOpenKeyboard] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [disableBtn, setDisableBtn] = useState(false)
    const [bank, setBank] = useState(false)
    const changePays = (clickedIndex) => {
        setPays(pays.map((pay, index) => {
            return index === clickedIndex
                ? { ...pay, active: true }
                : { ...pay, active: false };
        }));
    }

    

    useEffect(()=>{
        axios.post(`${BASE_URL}/api/get-pays`,{}, { withCredentials: true })
        .then(res => {
            setPays(res.data)
        })
    }, [])

    const activePay = pays.find(pay => pay.active);

    const pay = () => {
        let summ_ = summ.replace(' ', '')
        console.log(activePay)
        if(summ_.length == 0){
            setDisableBtn(false)
            showError("Введите сумму")
        }else if(summ_.length > 6){
            //error
        }
        else{
            if (activePay.type == 'card' && activePay.api){
                setDisableBtn(true)
                setAuth({...auth, loading: true})
                axios.post(`${BASE_URL}/api/chek-summ-deposit`,{
                    summa:summ_,
                    type: activePay.type,
                    name: activePay.name,
                }, { withCredentials: true })
                .then(res => {
                    if(res.data.status == "error min"){
                        showInfo(`Минимальная сумма для пополнения ${String(res.data.min_data).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽`)
                    }else if(res.data.status == "error max"){
                        showInfo(`Максимальная сумма для пополнения ${String(res.data.max_data).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽`)
                    }else if (res.data.status == "error"){
                        showInfo(`Не удалось создать заявку на пополнение: попробуйте повторить попытку позже или обратитесь в техническую поддержку.`)
                    }else if(res.data.status == "ok"){
                        showSelectBank()
                    }
                    setAuth({...auth, loading: false})
                    setDisableBtn(false)
                })
                
            }else{
                setDisableBtn(true)
                setAuth({...auth, loading: true})
                axios.post(`${BASE_URL}/api/create-deposit`,{
                    summa:summ_,
                    type: activePay.type,
                    name: activePay.name,
                }, { withCredentials: true })
                .then(res => {
                    setAuth({...auth, loading: false})
                    if(res.data.status == "ok"){
                        toStepSecondDeposit()
                        setReqDeposit(res.data.req)
                        setSummDepositSec(res.data.summ)
                    }else if(res.data.status == "error min"){
                        showInfo(`Минимальная сумма для пополнения ${String(res.data.min_data).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽`)
                    }else if(res.data.status == "error max"){
                        showInfo(`Максимальная сумма для пополнения ${String(res.data.max_data).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽`)
                    }else if (res.data.status == "error"){
                        showInfo(`Не удалось создать заявку на пополнение: попробуйте повторить попытку позже или обратитесь в техническую поддержку.`)
                    }
                    setDisableBtn(false)
                    
                })
            }
        }
    }
    const chekSumm = (val)=>{
        const input = val.replace(' ', '')
        if ((/[a-zA-Zа-яА-Я]|[^\w\s]/.test(input) == false && input.length < 7) || input == '') {
            let changeSumm = input.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            setSumm(changeSumm);
        }
    }

    const chekMobKeyboard=()=>{
        if(window.innerWidth <= 540){
            setOpenKeyboard(true)
        }
    }

    useEffect(()=>{
        if(window.innerWidth <= 540){
            setIsMobile(true)
        }
    }, [])

    return (
        <div class="modal modal__deposit">
        <div class="modal__body modal__body_w_720">
            <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
            <div class="modal__title">
                <span class="modal__title-text">Пополнение баланса</span>
                <span class="modal__title-line"></span>
            </div>
            {/* { 
             selectPay ?
                (<div class="modal__blocks">
                        <div className={"modal__block modal__deposit-block "} onClick={()=>{pay('card')}}>
                            <img src={sberbank} className={"modal__block-img modal__block-img_w_96"}
                             alt="Belarus"/>
                            <p class="modal__block-text">
                                Сбербанк
                            </p>
                        </div>
                        <div className={"modal__block modal__deposit-block "} onClick={()=>{pay('sbp')}}>
                            <img src={sbp} className={"modal__block-img"} style={{width: '47px'}}
                             alt="Eurasian"/>
                            <p class="modal__block-text">
                                СБП
                            </p>
                        </div>
                </div>)
                :
                (
                <> */}
                <div class="modal__blocks">
                    {
                        pays.map((pay, index) => {
                            return(
                                <div key={index} onClick={() => changePays(index)}
                                className={"modal__block modal__deposit-block "+(pay.active ? 'modal__block_active' : '')}>
                                    
                                    {pay.type === 'card' ? (
                                        <div style={{display: "flex", gap: 5+"px"}}>
                                            <img className={"modal__block-img "+(pay.type == 'card' ? 'modal__block-img_w_96' : '')+(pay.type == 'sbp' ? 'modal__block-img_w_48' : '')}
                                            src={require('../../assets/images/'+pay.img)} alt={pay.name}/>
                                            <img
                                                className="modal__block-img modal__block-img_w_48"
                                                src={require('../../assets/images/sbp.svg')}
                                                alt="SBP"
                                            />
                                        </div>
                                    ) : <img className={"modal__block-img "+(pay.type == 'card' ? 'modal__block-img_w_96' : '')+(pay.type == 'sbp' ? 'modal__block-img_w_48' : '')}
                                    src={require('../../assets/images/'+pay.img)} alt={pay.name}/>}                                    
                                    <p class="modal__block-text">
                                        {pay.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                    
                </div>
                <label class="label modal__label">Сумма</label>
                <div class="modal__input-wrapper modal__input-wrapper-sum">
                    <input class="input modal__input modal__input-sum modal__deposit-input-sum" type="text"
                        placeholder="Введите сумму" value={summ} onChange={event => chekSumm(event.target.value)} onClick={()=>{chekMobKeyboard()}} autoComplete={isMobile ? "on" : "off"} readOnly={isMobile ? true : false}/>
                </div>
                {/* <label class="label modal__deposit-label modal__label">{activePay.type}</label>
                <input class="input modal__deposit-input" type="text" value={activePay.value} readonly=""/> */}
                <button class="btn btn_background_blue modal__btn modal__btn-deposit"
                    onClick={()=>{pay()}}
                    disabled={disableBtn}>
                    <img src={deposit} alt="deposit"/>
                    Перейти к оплате
                </button>
                {/* </>
                ) */}
            </div>
            {
            openKeyboard ? <Keyboard close={()=>{setOpenKeyboard(false)}} summ={summ} setSumm={(data)=>{chekSumm(data)}}/>
            : ''
            }
            
    </div>
    )
}

export default Deposit;