import cross from "../../assets/images/cross.svg"
import support from "../../assets/images/support.svg"
import faq from "../../assets/images/faq.svg"
import reviews from "../../assets/images/reviews.svg"
import { Link } from "react-router-dom"
import React from 'react';

function Help({close}){
    return (
        <div class="modal modal__help">
                  <div class="modal__body modal__body_w_720">
                      <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
                      <div class="modal__title">
                          <span class="modal__title-text">Помощь</span>
                          <span class="modal__title-line"></span>
                      </div>
                      <div class="modal__blocks">
                          <a class="modal__block" href="https://t.me/PocketVertexSupport" target="_blank">
                              <img class="modal__block-img" src={support} alt="support"/>
                              <p class="modal__block-text">
                                  Служба<br/>
                                  поддержки
                              </p>
                          </a>
                          <Link class="modal__block" to="/faq">
                              <img class="modal__block-img" src={faq} alt="faq"/>
                              <p class="modal__block-text">
                                  Информационный раздел
                              </p>
                          </Link>
                          <Link class="modal__block" to="/reviews">
                              <img class="modal__block-img" src={reviews} alt="reviews"/>
                              <p class="modal__block-text">
                                  Отзывы
                              </p>
                          </Link>
                      </div>
                  </div>
              </div>
    )
}

export default Help;