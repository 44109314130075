import cross from "../../assets/images/cross.svg"
import cards from "../../assets/images/cards.svg"
import qiwi from "../../assets/images/qiwi.svg"
import youmoney from "../../assets/images/youmoney.svg"
import bitcoin from "../../assets/images/bitcoin.svg"
import withdrawal from "../../assets/images/withdrawal.svg"
import { useState, useEffect, useRef } from "react"
import axios from "axios"
import { BASE_URL } from "../../constanta/apiConfig";
import Keyboard from "./Keyboard"
import { showError, showWaitPay } from "../../constanta/Toastr"
import React from 'react';

function Withdrawal({close, setAuth, auth}){
    const [pays, setPays] = useState([
        {active: true, img: cards, name: "Банковская карта", value: '', type: 'Банковская карта'},
        {active: false, img: youmoney, name: "YooMoney", value: '', type: 'YooMoney'},
        {active: false, img: bitcoin, name: "Bitcoin", value: '', type: 'Bitcoin'}
    ])

    const [summ, setSumm] = useState('')
    const [openKeyboard, setOpenKeyboard] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const changePays = (clickedIndex) => {
        setPays(pays.map((pay, index) => {
            return index === clickedIndex
                ? { ...pay, active: true }
                : { ...pay, active: false };
        }));
    }

    const activePay = pays.find(pay => pay.active);

    const withdrawalFounds=()=>{

        let summ_ = summ.replace(' ', '')
        if(summ == '' && (activePay.value == '' || activePay.value == '+7')){
            showError('Введите сумму и реквизиты')
        }
        else if(summ == ''){
            showError('Введите сумму')
        }else if(activePay.value == '' || activePay.value == '+7'){
            showError('Введите реквизиты')
        }
        else if(summ_.length > 6){
            // showError('Максимальная сума для вывода 100000')
        }else if(summ_ > auth.balance){
            showError('Недостаточно средств для вывода')
        }
        else if(activePay.value == ''){
            //showError('Уведите реквизиты')
        }else if(summ_.length == 0){
            //showError('Уведите суму')
        }
        else{
            close()
            axios.post(`${BASE_URL}/api/create-withdrawal`,{
                type: activePay.type,
                requisites: activePay.value,
                summ: summ_,
                name: activePay.name
            }, { withCredentials: true })
            .then(res => {
                if(res.data.status == "ok"){
                    showWaitPay()
                    let new_balance = Math.round(auth.balance-summ_)
                    setAuth({...auth, balance: new_balance})
                }else if(res.data.status == "error"){
                    showError(res.data.message)
                }
                
            })
        }
    }

    const handleInputChange = (e) => {
        // if(activePay.type == 'QIWI' && newValue.length < 2){
        //     newValue = '+7'
        // }
        const newValue = e.target.value.replace(' ', '')
        const isQIWI = activePay.type === 'QIWI';
        
        // Если это QIWI и пользователь пытается изменить +7, игнорируем ввод
        if (isQIWI && !newValue.startsWith("+7") ) {
            newValue = '+7'
        }
        setPays(pays.map(pay => 
            
            pay.active ? {...pay, value: newValue} : pay
        ));
    };

    const chekSumm = (val)=>{
        const input = val.replace(' ', '')
        if ((/[a-zA-Zа-яА-Я]|[^\w\s]/.test(input) == false && input.length < 7) || input == '') {
            let changeSumm = input.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            setSumm(changeSumm);
        }
    }

    const chekMobKeyboard=()=>{
        if(window.innerWidth <= 540){
            setOpenKeyboard(true)
        }
    }

    useEffect(()=>{
        if(window.innerWidth <= 540){
            setIsMobile(true)
        }
    }, [])


    return (
        <div class="modal modal__withdrawal">
        <div class="modal__body modal__body_w_720">
            <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
            <div class="modal__title">
                <span class="modal__title-text">Вывод</span>
                <span class="modal__title-line"></span>
            </div>
            <div class="modal__blocks">
            {
                    pays.map((pay, index) => {
                        return(
                            <div key={index} onClick={() => changePays(index)}
                            className={"modal__block modal__deposit-block "+(pay.active ? 'modal__block_active' : '')}>
                                <img className={"modal__block-img "+(pay.type == 'Банковская карта' ? 'modal__block-img_w_140' : '')}
                                 src={pay.img} alt={pay.name}/>
                                <p class="modal__block-text">
                                    {pay.name}
                                </p>
                            </div>
                        )
                    })
                }
                
            </div>
            <label class="label modal__label">Сумма</label>
            <div class="modal__input-wrapper modal__input-wrapper-sum">
                <input class="input modal__input modal__input-sum modal__withdrawal-input-sum" type="text"
                    placeholder="Введите сумму" value={summ} onChange={event => chekSumm(event.target.value)} onClick={()=>chekMobKeyboard()} autoComplete={isMobile ? "on" : "off"} readOnly={isMobile ? true : false}/>
            </div>
            <label class="label modal__withdrawal-label modal__label">{activePay.type}</label>
            <input class="input modal__withdrawal-input modal__withdrawal-payment-details" type="text"
                placeholder="Введите реквизиты" value={activePay.value} onChange={handleInputChange}
                />
            <a class="btn btn_background_blue modal__btn modal__btn-withdrawal"
                onClick={withdrawalFounds}
                href="#">
                <img src={withdrawal} alt="withdrawal"/>
                Создать запрос на вывод
            </a>
        </div>
        {
        openKeyboard ? <Keyboard close={()=>{setOpenKeyboard(false)}} summ={summ} setSumm={(data)=>{chekSumm(data)}}/>
        : ''
        }
    </div>
    )
}

export default Withdrawal;