import Header from "../components/Header";
import ReviewsItems from "../components/ReviewsItems";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context";
import React from 'react';
import axios from "axios";
import { BASE_URL } from "../constanta/apiConfig";

function Reviews(){
    const {auth, setAuth} = useContext(AuthContext)
    const [reviews, setReviews] = useState([])
    useEffect(() => {
        setAuth({...auth, loading: true});
        const timer = setTimeout(() => setAuth({...auth, loading: false}), 500);
        axios.post(`${BASE_URL}/api/get-reviews`,{}, { withCredentials: true })
        .then(res => {
            console.log(res.data)
            setReviews(res.data.reviews)
        })
        return () => clearTimeout(timer);
      }, []);

    
      
    return (
        <div class="wrapper" style={{'minHeight': 100+'vh'}}>
            <Header/>
            <main class="main">

                <section class="reviews">
                    <div class="container reviews__container">
                        <h1 class="title">
                            Отзывы
                        </h1>
                        <h2 class="subtitle">
                            Недавние отзывы пользователей сервиса
                        </h2>
                        <div class="reviews__grid">
                            {
                                reviews.map((review, index) => {
                                    return(
                                        <ReviewsItems key={review.id} review={review}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default Reviews;